<div class="d-flex align-items-center">
    <wdx-dropdown
        ngbDropdown
        container="body"
        #dropdown="ngbDropdown"
        display="dynamic"
        placement="bottom-end"
    >
        <button
            ngbDropdownToggle
            class="btn fw-normal d-flex align-items-center"
        >
            <wdx-icon
                class="align-self-center"
                iconStyle="fas"
                icon="user"
                [scale]="1.5"
            ></wdx-icon>

            <wdx-icon
                class="ms-2 toggle-icon"
                icon="chevron-down"
                [rotate]="dropdown.isOpen() ? 180 : 0"
            ></wdx-icon>
        </button>
        <wdx-dropdown-menu ngbDropdownMenu>
            <div
                class="d-flex flex-column align-items-center justify-content-center py-4 border-bottom"
            >
                <span class="fw-bold">{{ me?.userName }}</span>
            </div>
            <a
                *ngFor="let menuItem of menuItems"
                class="d-flex"
                [routerLink]="menuItem.routerLink"
                ngbDropdownItem
            >
                <wdx-icon
                    class="me-2"
                    [icon]="menuItem.icon"
                    [fixedWidth]="true"
                ></wdx-icon>
                {{ menuItem.label }}
            </a>
            <button
                *ngIf="currentTheme$ | async as currentTheme"
                ngbDropdownItem
                (click)="toggleTheme()"
            >
                <wdx-icon
                    class="me-2"
                    [icon]="
                        currentTheme === iThemeSetting.Dark ? 'sun' : 'moon'
                    "
                    [fixedWidth]="true"
                    size="lg"
                ></wdx-icon>
                <ng-container *ngIf="currentTheme === iThemeSetting.Dark"
                    >Switch to Light Theme</ng-container
                >
                <ng-container *ngIf="currentTheme !== iThemeSetting.Dark"
                    >Switch to Dark Theme</ng-container
                >
            </button>
            <button
                class="btn fw-normal py-2 w-100 text-start"
                (click)="logout()"
            >
                <wdx-icon
                    class="me-1"
                    icon="right-from-bracket"
                    [fixedWidth]="true"
                ></wdx-icon>
                Log out
            </button>
        </wdx-dropdown-menu>
    </wdx-dropdown>
</div>
