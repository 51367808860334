@if (tenantName$ | ngrxPush; as tenantName) {
    <div class="d-flex align-items-center">
        @if (portalLogoUrl) {
            <a
                class="d-none d-md-block navbar-brand p-0 me-2 divider-end"
                routerLink="/"
            >
                <img [src]="portalLogoUrl" />
            </a>
        }

        <span class="fs-5 ms-2" data-cy="logo-tenant-name">
            {{ tenantName }}</span
        >
    </div>
}
