import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    isReady$ = new BehaviorSubject<boolean>(true);
    tenantName$ = new BehaviorSubject<string>('');
}
