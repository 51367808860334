import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppService } from '../../../core/services/app/app.service';
import { UserApiService } from '../../../libs/api-contract';
import { filter, take } from 'rxjs';
import { AppShellComponent } from '../app-shell/app-shell.component';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, AppShellComponent, WdxSpinnerModule],
    templateUrl: './app-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPageComponent implements OnInit {
    constructor(
        private userApiService: UserApiService,
        private appService: AppService,
    ) {}

    ngOnInit(): void {
        this.userApiService
            .getTenant()
            .pipe(
                take(1),
                filter((tenant) => Boolean(tenant)),
            )
            .subscribe((tenant) =>
                this.appService.tenantName$.next(
                    tenant[0].tenantName as string,
                ),
            );
    }
}
