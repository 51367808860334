import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SitemapEntry } from '@wdx/portal/api-models';
import { Observable, map, shareReplay } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './sitemap-url-segment';

export type SiteMapCode =
    | 'insights'
    | 'portfolios'
    | 'messages'
    | 'documents'
    | 'team'
    | 'todo';

@Injectable({ providedIn: 'root' })
export class SitemapApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    private sitemap$?: Observable<SitemapEntry[]>;

    getSitemap(): Observable<SitemapEntry[]> {
        if (!this.sitemap$) {
            this.sitemap$ = this.get<SitemapEntry[]>(
                `${UrlSegment.Sitemap}`,
            ).pipe(shareReplay(1));
        }
        return this.sitemap$;
    }

    hasFeature(feature: SiteMapCode) {
        return this.getSitemap().pipe(
            map((sitemap) =>
                sitemap.some((item) => (item.code as SiteMapCode) === feature),
            ),
        );
    }

    getEntryLabel$(
        labelKey: string,
        fallbackLabel: string,
    ): Observable<string> {
        return this.getSitemap().pipe(
            map((sitemap) =>
                this.getEntryLabel(sitemap, labelKey, fallbackLabel),
            ),
        );
    }

    getEntryLabel(
        sitemap: SitemapEntry[],
        labelKey: string,
        fallbackLabel: string,
    ): string {
        return (
            sitemap.find((entry) => entry.code === labelKey)?.displayName
                ?.value || fallbackLabel
        );
    }
}
